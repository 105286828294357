import React from "react";

import Layout from "../layout/layout";

const PageNotFound = () => {

  return (
    <Layout>
    <p>Oops... You have ordered something that we do not serve.</p>
    </Layout>
  );
};

export default PageNotFound;
